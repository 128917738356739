// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-foodtech-js": () => import("/opt/build/repo/src/pages/foodtech.js" /* webpackChunkName: "component---src-pages-foodtech-js" */),
  "component---src-pages-fr-index-js": () => import("/opt/build/repo/src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-services-js": () => import("/opt/build/repo/src/pages/fr/services.js" /* webpackChunkName: "component---src-pages-fr-services-js" */),
  "component---src-pages-fr-team-js": () => import("/opt/build/repo/src/pages/fr/team.js" /* webpackChunkName: "component---src-pages-fr-team-js" */),
  "component---src-pages-fr-works-js": () => import("/opt/build/repo/src/pages/fr/works.js" /* webpackChunkName: "component---src-pages-fr-works-js" */),
  "component---src-pages-fr-works-animan-js": () => import("/opt/build/repo/src/pages/fr/works/animan.js" /* webpackChunkName: "component---src-pages-fr-works-animan-js" */),
  "component---src-pages-fr-works-cos-js": () => import("/opt/build/repo/src/pages/fr/works/cos.js" /* webpackChunkName: "component---src-pages-fr-works-cos-js" */),
  "component---src-pages-fr-works-lyrique-app-js": () => import("/opt/build/repo/src/pages/fr/works/lyrique-app.js" /* webpackChunkName: "component---src-pages-fr-works-lyrique-app-js" */),
  "component---src-pages-fr-works-perle-js": () => import("/opt/build/repo/src/pages/fr/works/perle.js" /* webpackChunkName: "component---src-pages-fr-works-perle-js" */),
  "component---src-pages-fr-works-ppi-js": () => import("/opt/build/repo/src/pages/fr/works/ppi.js" /* webpackChunkName: "component---src-pages-fr-works-ppi-js" */),
  "component---src-pages-fr-works-susfinteq-js": () => import("/opt/build/repo/src/pages/fr/works/susfinteq.js" /* webpackChunkName: "component---src-pages-fr-works-susfinteq-js" */),
  "component---src-pages-fr-works-tft-js": () => import("/opt/build/repo/src/pages/fr/works/tft.js" /* webpackChunkName: "component---src-pages-fr-works-tft-js" */),
  "component---src-pages-fr-works-vr-restaurant-js": () => import("/opt/build/repo/src/pages/fr/works/vr-restaurant.js" /* webpackChunkName: "component---src-pages-fr-works-vr-restaurant-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-works-js": () => import("/opt/build/repo/src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-animan-js": () => import("/opt/build/repo/src/pages/works/animan.js" /* webpackChunkName: "component---src-pages-works-animan-js" */),
  "component---src-pages-works-cos-js": () => import("/opt/build/repo/src/pages/works/cos.js" /* webpackChunkName: "component---src-pages-works-cos-js" */),
  "component---src-pages-works-lyrique-app-js": () => import("/opt/build/repo/src/pages/works/lyrique-app.js" /* webpackChunkName: "component---src-pages-works-lyrique-app-js" */),
  "component---src-pages-works-perle-js": () => import("/opt/build/repo/src/pages/works/perle.js" /* webpackChunkName: "component---src-pages-works-perle-js" */),
  "component---src-pages-works-ppi-js": () => import("/opt/build/repo/src/pages/works/ppi.js" /* webpackChunkName: "component---src-pages-works-ppi-js" */),
  "component---src-pages-works-susfinteq-js": () => import("/opt/build/repo/src/pages/works/susfinteq.js" /* webpackChunkName: "component---src-pages-works-susfinteq-js" */),
  "component---src-pages-works-tft-js": () => import("/opt/build/repo/src/pages/works/tft.js" /* webpackChunkName: "component---src-pages-works-tft-js" */),
  "component---src-pages-works-vr-restaurant-js": () => import("/opt/build/repo/src/pages/works/vr-restaurant.js" /* webpackChunkName: "component---src-pages-works-vr-restaurant-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

